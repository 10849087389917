import formatCurrency from '../../../../helpers/formatCurrency';

export default (compareAtPrice, variantPrice = '0', locale, currency, pricingDecimals=null) => {
  return (
    compareAtPrice?.sym && compareAtPrice.value !== "" ?
      compareAtPrice.sym === "%" ?
        formatCurrency((parseFloat(variantPrice) * parseFloat(compareAtPrice.value) / 100), locale, currency, pricingDecimals)
        :
        formatCurrency(parseFloat(compareAtPrice.value), locale, currency, pricingDecimals)
      :
      formatCurrency(variantPrice, locale, currency, pricingDecimals)
  )
}